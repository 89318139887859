import 'lazysizes';
$().ready(function(){
    var html = document.getElementsByTagName('html')[0];
    if(html){
        html.classList.remove("no-js");
        html.classList.add("js");
    }
    setupSlideMenu();
    if ($('form').length){
        $('form').each(function(){
            /* no float labels */
            $(this).validate();
        });
    }
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    if (document.querySelectorAll("div.scroller .item")){
        scrollTestimonials();
    }
    if (document.querySelectorAll("div.switch-list")){
        toggleChildren();
    }
    if (document.querySelectorAll("div.service-item.expander")){
        toggleServices();
    }
    if (document.querySelectorAll(".item-block.has-content")){
        toggleIndexItems();
    }
});
$(window).on("load", function(){
    // after images have loaded
    setSwapWrapHeight();
});
var delay;
$(window).on("resize", function(){
    clearTimeout(delay);
    delay = setTimeout(function(){setSwapWrapHeight();}, 100);
});

function setupSlideMenu(){
    // recurse is disabled
    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
}

function scrollTestimonials(){
    var items = document.querySelectorAll(".scroller .item");
    if (items.length > 1){
        // only one in site currently
        var wrap = document.getElementsByClassName("scroll-wrap")[0];
        var scroll = wrap.children[0];
        var nav = wrap.children[1];
        var logo_wrap = wrap.children[2];
        updateLogos();
        nav.style.display = "block";

        if (scroll.scrollWidth > wrap.clientWidth){
            if(!scroll.getAttribute("data-animating")){
                setTimeout(slide, 5000);
            }
        } else if (scroll.getAttribute("data-animating")){
            scroll.classList.remove("slide");
            scroll.style.transform = "translateX(0px)";
            scroll.setAttribute("data-animating", false);
        }

        function slide(){
            scroll.setAttribute("data-animating", true);
            scroll.classList.add("slide");

            //listen
            scroll.addEventListener('webkitTransitionEnd', reset);
            scroll.addEventListener('oTransitionEnd', reset);
            scroll.addEventListener('transitionend', reset);

            var current = scroll.children[0];
            var distance = current.clientWidth;
            var next = scroll.children[1];

            scroll.style.transform = "translateX(-"+distance+"px)";

            // set active
            current.classList.remove("active");
            next.classList.add("active");
            var id = next.getAttribute("id");
            nav.children.forEach(function(n){
                if (n.getAttribute("data-id") != id){
                    n.classList.remove("active");
                }
                else n.classList.add("active");
            });
            updateLogos();
        }
        function updateLogos(){
            var active = scroll.querySelector(".active");
            var logos = JSON.parse(active.getAttribute("data-id"));
            [...logo_wrap.querySelectorAll(".logo")].forEach(function(logo){
                logo.classList.remove("active");
            });
            if (logos)
            logos.forEach(function(logo) {
                var logo_el = logo_wrap.querySelector("#" + logo);
                if (logo_el) logo_el.classList.add("active");
            });
        }
        function reset(){
            scroll.classList.remove("slide");
            //ignore the next transition
            scroll.removeEventListener('webkitTransitionEnd', reset, false);
            scroll.removeEventListener('oTransitionEnd', reset, false);
            scroll.removeEventListener('transitionend', reset, false);

            scroll.appendChild(scroll.children[0]);
            scroll.style.transform = "translateX(0px)";
            setTimeout(slide, 5000);
        }
    }
}

function toggleServices(){
    var open_services = document.querySelectorAll(".service-item.expander.open");
    if (open_services){
        for(var i=0; i < open_services.length; i++){
            setContentHeight(open_services[i]);
        }
    }
    var service_headings = document.getElementsByClassName("service-toggle");
    for(var i=0; i < service_headings.length; i++){
        service_headings[i].addEventListener('click', function(e){
            var el = e.target.parentElement;
            el.classList.toggle('open');
            if (el.classList.contains('open')){
                setContentHeight(el);
            } else {
                var content_div = el.children[1];
                content_div.style.maxHeight = "0px";
            }
        });
    }
    function setContentHeight(open_service){
        var content_div = open_service.children[1];
        var maxH = Math.ceil(content_div.scrollHeight) + 40; /* padding */
        content_div.style.maxHeight = maxH+"px";
    }
}

function setSwapWrapHeight(){
//set the max height of wrapper so the swap area doesn't jump around
    var maxH = 0;
    var swap_wraps = document.querySelectorAll(".swap-list .body-wrap");
    for(var i=0; i < swap_wraps.length; i++){
        swap_wraps[i].children.forEach(function(g){
            var g_height = Math.ceil(g.clientHeight);
            maxH = (g_height > maxH)? g_height : maxH;
        });
        swap_wraps[i].style.minHeight = maxH+"px";
    }
}
function toggleChildren(){
    var switch_items = document.querySelectorAll("li.switch-content");
    for(var i=0; i < switch_items.length; i++){
        switch_items[i].addEventListener('click', function(e){
            // the list
            var current_li = e.currentTarget;
            var d_id = current_li.getAttribute("data-id");
            switch_items.forEach(function(l){
                l.classList.remove("active");
            });
            current_li.classList.add("active");
            // the content
            var new_content = document.getElementById(d_id);
            var content_divs = current_li.closest('.swap-list').querySelector('.body-wrap').children;
            content_divs.forEach(function(d){
                d.classList.remove("active");
            });
            new_content.classList.add('active');
        });
    }
}


function toggleIndexItems(){
    var toggle_areas = document.querySelectorAll(".item-block.has-content .toggle-open");
    for (var i=0; i < toggle_areas.length; i++){
        toggle_areas[i].addEventListener('click', function(e){
            var item_block = e.currentTarget.parentElement.parentElement;
            var button_span = e.currentTarget.children[2];
            item_block.classList.toggle('open');
            if (item_block.classList.contains('open')){
                button_span.innerText = "Read Less";
                item_block.querySelector('.body-wrap').scrollIntoView({behavior: "smooth"});
            } else {
                button_span.innerText = "Read More";
            }
        });
    }
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});